@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.cash {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 24px;

  .clear {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      gap: 8px;

      .counter {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .title {
        color: var(--cst-top-up-cash-out-method-title);
        @include typography-base;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }

  .additional-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .items {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
}
